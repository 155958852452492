import errorGraphciLong from "assets:/error-graphic-long.svg?url";
import errorGraphciShort from "assets:/error-graphic-short.svg?url";
import { buttonVariants } from "package:/components/elements/Button";
import type { ApiError } from "@greentrails/api";
import { defineComponent, useLanguage, useTranslations, useRequestURL } from "#imports";

export const ErrorPage = defineComponent(
  (props: { error?: Error | ApiError | null }) => {
    const t = useTranslations();
    const status = props.error?.status || props.error?.statusCode || 500;
    const requestURL = useRequestURL();

    return () => (
      <div class="@container">
        <div class="@lg:content min-h-screen grid-rows-[1fr_auto] gap-0 px-10 pt-32 pb-24 md:pb-32">
          <div class="col-span-12">
            {status === 404 ? (
              <div class="max-w-[800px] pb-12">
                <h1 class="text-4xl @2xl:text-6xl">{t("error.404.title")}</h1>
                <p class="pt-8" innerHTML={t("error.404.message", {
                    url: `mailto:info@greentrails.de?body=${encodeURI(requestURL.href)}`,
                  })} />
              </div>
            ) : (
              <div class="max-w-[800px] pb-12">
                <h1 class="text-xl">{t("error.500.title")}</h1>
                <p class="pt-8">{t("error.500.message")}</p>
              </div>
            )}

            {import.meta.env.DEV && props.error && (
              <div class="mb-12 bg-red p-10">
                <p>{props.error?.message}</p>
                <pre class="pt-10">{props.error?.stack}</pre>
              </div>
            )}
          </div>

          <div class="col-span-12 flex flex-col items-center md:items-start">
            <div class="inline-block pb-20">
              <img
                class="hidden sm:block"
                loading="lazy"
                decoding="async"
                src={errorGraphciLong}
                alt={errorGraphciLong}
              />
              <img
                class="block sm:hidden"
                loading="lazy"
                decoding="async"
                src={errorGraphciShort}
                alt={errorGraphciShort}
              />
            </div>
            <div class="inline-block">
              <a href="/" class={buttonVariants.white}>
                <span>{t("error.button.recover")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  },
  {
    name: "ErrorPage",
    props: ["error"],
  },
);
