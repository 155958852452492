import { GreenTrailsApiClient } from "@greentrails/api";
import type { DehydratedState, VueQueryPluginOptions } from "@tanstack/vue-query";
import { QueryClient, VueQueryPlugin, dehydrate, hydrate } from "@tanstack/vue-query";
import { defineNuxtPlugin, useEnv, useFlags, useLanguage, useState } from "#imports";

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>("vue-query");
  const flags = useFlags();

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient();
  const options: VueQueryPluginOptions = { queryClient };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    nuxt.hooks.hook("app:rendered", () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    nuxt.hooks.hook("app:created", () => {
      hydrate(queryClient, vueQueryState.value);
    });
  }

  const env = useEnv();
  const lang = useLanguage();

  const apiOrigin = flags.api_origin ? flags.api_origin : env.API_URL;
  const apiClient = new GreenTrailsApiClient({
    baseUrl: `${apiOrigin}/api`,
    assetsUrl: apiOrigin,
    lang: lang.value,
  });

  return {
    provide: {
      apiClient,
    },
  };
});
