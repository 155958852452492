import type { LogObject } from "@luckydye/log";
import { captureEvent, captureException } from "@sentry/vue/esm/index";

export class SentryWriteStream extends WritableStream {
  timer?: any;

  batch: LogObject[] = [];

  async flush() {
    for (const msg of this.batch) {
      if (msg.level === "error") {
        const err = msg.args.find((arg) => arg instanceof Error);
        if (err) {
          captureException(err);
        } else {
          captureEvent({
            message: msg.message,
            extra: msg,
          });
        }
      }
    }

    this.batch.length = 0;
  }

  constructor() {
    super({
      write: async (msg: LogObject) => {
        this.batch.push(msg);

        if (this.timer !== undefined) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.flush(), 1000);
      },
    });
  }
}
