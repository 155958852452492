/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NavigationResource } from '../models/NavigationResource';
import type { PageResource } from '../models/PageResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PageService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any Array of `NavigationResource`
   * @throws ApiError
   */
  public pagesNavigation(): CancelablePromise<{
    data: Array<NavigationResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pages/navigation',
    });
  }
  /**
   * @param page The page slug
   * @returns any `PageResource`
   * @throws ApiError
   */
  public pagesShow(
    page: Array<string>,
  ): CancelablePromise<{
    data: PageResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pages/{page}',
      path: {
        'page': page,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
}
