import revive_payload_client_1yGgOnb33i from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NPF9mshR3Q from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9DXnLFdLyB from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_3Fyc3fWabx from "/frontend/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.1_@vue+compiler-core@3.4.31_nuxt@3.11.1_postcss@8._aeginiln7vprndm4t3fv5lhbmq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_WJMWH5hGqu from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Qv2xAhqWUE from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_dnsZjF9p8H from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_0o9Ufy7rCv from "/frontend/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.14.9_@unocss+reset@0.61.0_floating-vue@5.2.2_rollup@2.79.1_typescr_pslpmjlpoevccrx5g2au2zoyza/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import query_3PIpCcvP3V from "/frontend/web/plugins/query.ts";
import offline_client_k8efBmWoZT from "/frontend/web/plugins/offline.client.ts";
import capacitor_client_C3Vr2AN4Tm from "/frontend/web/plugins/capacitor.client.ts";
import tracking_client_2phnYTxQAe from "/frontend/web/plugins/tracking.client.ts";
import sentry_client_GVhFdEXzN3 from "/frontend/web/plugins/sentry.client.ts";
import maplibre_client_OreqxaS3Zt from "/frontend/web/plugins/maplibre.client.ts";
export default [
  revive_payload_client_1yGgOnb33i,
  unhead_NPF9mshR3Q,
  router_9DXnLFdLyB,
  _0_siteConfig_3Fyc3fWabx,
  payload_client_WJMWH5hGqu,
  check_outdated_build_client_Qv2xAhqWUE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dnsZjF9p8H,
  chunk_reload_client_0o9Ufy7rCv,
  query_3PIpCcvP3V,
  offline_client_k8efBmWoZT,
  capacitor_client_C3Vr2AN4Tm,
  tracking_client_2phnYTxQAe,
  sentry_client_GVhFdEXzN3,
  maplibre_client_OreqxaS3Zt
]