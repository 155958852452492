/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsResource } from '../models/NewsResource';
import type { NewsShortResource } from '../models/NewsShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NewsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param news The news slug
   * @returns any `NewsResource`
   * @throws ApiError
   */
  public newsShow(
    news: Array<string>,
  ): CancelablePromise<{
    data: NewsResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/news/{news}',
      path: {
        'news': news,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param page
   * @param perPage
   * @returns any Paginated set of `NewsShortResource`
   * @throws ApiError
   */
  public newsIndex(
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    data: Array<NewsShortResource>;
    links: {
      first: string | null;
      last: string | null;
      prev: string | null;
      next: string | null;
    };
    meta: {
      current_page: number;
      from: number | null;
      last_page: number;
      /**
       * Generated paginator links.
       */
      links: Array<{
        url: string | null;
        label: string;
        active: boolean;
      }>;
      /**
       * Base path for paginator generated URLs.
       */
      path: string | null;
      /**
       * Number of items shown per page.
       */
      per_page: number;
      /**
       * Number of the last item in the slice.
       */
      to: number | null;
      /**
       * Total number of items being paginated.
       */
      total: number;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/news',
      query: {
        'page': page,
        'per_page': perPage,
      },
      errors: {
        403: `Authorization error`,
        422: `Validation error`,
      },
    });
  }
}
