import { twMerge } from "tailwind-merge";
import { defineComponent } from "vue";

export const MobileSafeArea = defineComponent(
  (
    props: {
      class?: string;
      /** Wether to apply top padding, falls back to true */
      top?: boolean;
      /** Wether to apply bottom padding, falls back to true */
      bottom?: boolean;
      /** Wether to apply top padding, falls back to true */
      left?: boolean;
      /** Wether to apply bottom padding, falls back to true */
      right?: boolean;
    },
    { slots },
  ) => {
    return () => (
      <div
        class={twMerge(
          props.top === false ? "" : "pt-[var(--safe-area-inset-top)]",
          props.bottom === false ? "" : "pb-[var(--safe-area-inset-bottom)]",
          props.left === false ? "" : "pl-[var(--safe-area-inset-left)]",
          props.right === false ? "" : "pr-[var(--safe-area-inset-right)]",
          props.class,
        )}
      >
        {slots.default?.()}
      </div>
    );
  },
  {
    name: "MobileSafeArea",
    props: ["class", "top", "bottom", "left", "right"],
  },
);
