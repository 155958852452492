import { shouldUseOfflineData } from "package:/composables/useNetwork";
import { ERROR_CODE, debug, error } from "package:/utils";
import { IndexOfflineStorage } from "@greentrails/api/offline";
import { toast } from "~/components/elements/Toasts";
import {
  defineNuxtPlugin,
  useApiClient,
  useEnv,
  useFlags,
  useLanguage,
  useTranslations,
} from "#imports";

export default defineNuxtPlugin(() => {
  const api = useApiClient();
  const lang = useLanguage();
  const t = useTranslations();
  const env = useEnv();
  const flags = useFlags();
  const apiOrigin = flags.api_origin ? flags.api_origin : env.API_URL;
  const offlineStorage = new IndexOfflineStorage({
    baseUrl: `${apiOrigin}/api`,
    assetsUrl: apiOrigin,
    lang: lang.value,
  });

  if (flags.offline) {
    offlineStorage.init().catch((err) => {
      error("offlineStorage.init ", err);
      toast({
        variant: "error",
        message: t("misc.error", { code: ERROR_CODE["offline-storage-init"] }),
        time: 5000,
      });
    });

    navigator.storage.persist().then((allowed) => {
      debug("persist", allowed);
    });
  }

  api.request.on(async (req) => {
    if (shouldUseOfflineData()) {
      debug("storage middleware", "url", req);

      let path = `${req.url}${req.query ? `?${JSON.stringify(req.query)}` : ""}`;

      if (req.path) {
        for (const [key, value] of Object.entries(req.path)) {
          path = path.replace(`{${key}}`, value);
        }
      }

      const storage = await offlineStorage.get(path);

      if (storage) {
        debug("storage middleware", "url", path, "hit", storage.data);

        const blob = storage.data;
        const data = JSON.parse(await blob.text());
        return data;
      }
    }

    return;
  });

  return {
    provide: {
      offlineStorage,
    },
  };
});
