import { Button, buttonVariants } from "package:/components/elements/Button";
import { Dialog } from "package:/components/elements/Dialog";
import { Actions } from "actions";
import { useVersion } from "package:/composables/useVersion";
import { parseVersion } from "package:/utils";
import { defineComponent, ref, useEnv, useTranslations, computed } from "#imports";
import { useApi } from "~/composables/useApi";
import { Link } from "~/components/elements/Link";

export const UpdateDialog = defineComponent(() => {
  const t = useTranslations();
  const env = useEnv();
  const version = useVersion();

  const needsUpdate = ref(false);
  const storeUrl =
    env.ENVIROMENT === "android"
      ? t("homepage.app.playstore.url")
      : env.ENVIROMENT === "ios"
        ? t("homepage.app.appstore.url")
        : null;

  const global = useApi((api) => {
    return api.index.index();
  });

  global.suspense().then((globals) => {
    const data = globals.data;

    if (!data) {
      return;
    }

    if (data.minRequiredVersion && data.minRequiredVersion !== env.VERSION) {
      const required = parseVersion(data.minRequiredVersion);
      needsUpdate.value = !(
        version.major >= required.major &&
        version.minor >= required.minor &&
        version.patch >= required.patch
      );
    }
  });

  Actions.register("updatedialog.open", {
    async run() {
      needsUpdate.value = true;
    },
  });

  const showDialog = computed<boolean>(() => {
    return (env.PLATFORM === "native" || import.meta.env.DEV) && needsUpdate.value;
  });

  return () => {
    return (
      <a-portal>
        {showDialog.value && storeUrl && (
          <Dialog open={true}>
            <h2 class="pb-1 text-xl">{t("update.title")}</h2>
            <p>{t("update.message")}</p>

            <div class="mt-4">
              <Link class={buttonVariants.big} href={storeUrl}>
                Download Update
              </Link>
            </div>
          </Dialog>
        )}
      </a-portal>
    );
  };
});
