/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AreaGeoResource } from '../models/AreaGeoResource';
import type { AreaResource } from '../models/AreaResource';
import type { AreaShortResource } from '../models/AreaShortResource';
import type { EventShortResource } from '../models/EventShortResource';
import type { PoiCategoryResource } from '../models/PoiCategoryResource';
import type { PoiShortResource } from '../models/PoiShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AreaService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param area The area slug
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiCategoryResource`
   * @throws ApiError
   */
  public areasPoiCategories(
    area: Array<string>,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiCategoryResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas/{area}/pois/categories',
      path: {
        'area': area,
      },
      query: {
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param area The area slug
   * @param hasCategory
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiShortResource`
   * @throws ApiError
   */
  public areasPois(
    area: Array<string>,
    hasCategory?: string | null,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas/{area}/pois',
      path: {
        'area': area,
      },
      query: {
        'has_category': hasCategory,
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param area The area slug
   * @returns any `AreaGeoResource`
   * @throws ApiError
   */
  public areasGeoData(
    area: Array<string>,
  ): CancelablePromise<{
    data: AreaGeoResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas/{area}/geo-data',
      path: {
        'area': area,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param area The area slug
   * @returns any Array of `EventShortResource`
   * @throws ApiError
   */
  public areasEvents(
    area: Array<string>,
  ): CancelablePromise<{
    data: Array<EventShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas/{area}/events',
      path: {
        'area': area,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param area The area slug
   * @returns any `AreaResource`
   * @throws ApiError
   */
  public areasShow(
    area: Array<string>,
  ): CancelablePromise<{
    data: AreaResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas/{area}',
      path: {
        'area': area,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @returns any Array of `AreaShortResource`
   * @throws ApiError
   */
  public areasIndex(): CancelablePromise<{
    data: Array<AreaShortResource>;
    bounds: any[];
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/areas',
    });
  }
}
