import { App, type URLOpenListenerEvent } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { SafeArea } from "capacitor-plugin-safe-area";
import { defineNuxtPlugin, useRouter } from "#imports";
import { useEnv } from "package:/composables/useEnv.js";
import { download } from "package:/utils";

// const checkAppLaunchUrl = async () => {
//   const launchUrl = await App.getLaunchUrl();
//   console.info(`App opened with URL: ${launchUrl?.url}`);
// };

// biome-ignore lint/style/noDefaultExport: framework
export default defineNuxtPlugin((app) => {
  const router = useRouter();
  const env = useEnv();

  App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
    // universal links handling, appUrlOpen is triggered when the app is opened with a URL e.g. with https://greentrails.de/news/123
    const url = event.url.includes(env.SITE_URL) ? new URL(event.url) : undefined;

    //TODO maybe extract exceptions to a config file
    // This does not work reliably, because the app is not yet fully loaded when this event is triggered
    if (url?.pathname === "/teilnahmebedingungen") {
      download(url.href);
    }

    router.push(url ? `${url.pathname}${url.search}` : "/");
  });

  // App.addListener("appRestoredResult", (data) => {
  //   console.info("Restored state:", data);
  // });

  App.addListener("backButton", () => {
    if (window.history.state?.back) {
      window.history.back();
    } else {
      App.exitApp();
    }
  });

  app.hooks.hookOnce("app:mounted", () => {
    // manually hide spash screen to prevent it from showing a white screen
    setTimeout(() => {
      SplashScreen.hide();
    }, 250);
  });

  SafeArea.getSafeAreaInsets().then(({ insets }) => {
    document.documentElement.style.setProperty(
      "--safe-area-inset-top",
      `${insets.top}px`,
    );
    document.documentElement.style.setProperty(
      "--safe-area-inset-bottom",
      `${insets.bottom}px`,
    );
  });
});
