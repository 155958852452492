import { type ClassNameValue, twMerge } from "tailwind-merge";
import { defineComponent } from "vue";

const base = [
  // "inline-flex items-center gap-2 cursor-pointer leading-none p-4 active:transition-none pointer-events-auto",
  "inline-flex items-center gap-2 cursor-pointer leading-none p-4 active:transition-none",
];

const defaultVariant = [
  "rounded-full bg-green-500 text-white transition-colors duration-200",
  "focus:outline-none focus-visible:ring",
  "hover:bg-purple-500 hover:text-white active:bg-white active:text-green-800",
];

const purple = [defaultVariant, "bg-purple-500 hover:bg-purple-400"];

const white = [defaultVariant, "text-black bg-white hover:text-black hover:bg-gray"];

const ghost = [
  "bg-transparent transition-colors duration-200 px-0 py-3",
  "focus:outline-none focus-visible:ring",
  "hover:text-white active:white rounded-md",
];

const big = [
  "px-5 h-[3.175rem] max-h-[3.175rem] gap-3 bg-purple-400 text-white text-base font-normal rounded-full",
  "transition-colors duration-200",
  "hover:bg-purple-500",
  "focus-visible:ring",
];

const smallBase = [
  "px-4 h-8 max-h-8 gap-2 text-xs font-bold uppercase [&_.icon]:text-[1.2em]",
];

const iconDark = [
  "bg-green-500 text-white text-base rounded-full w-[3.175rem] h-[3.175rem] relative *:absolute *:top-1/2 *:left-1/2 *:transform *:-translate-x-1/2 *:-translate-y-1/2",
  "hover:bg-purple-500",
  "focus-visible:ring",
  "active:text-green-900 active:bg-white",
];

export const buttonVariants = {
  default: twMerge(base, defaultVariant),
  purple: twMerge(base, purple),
  white: twMerge(base, white),
  ghost: twMerge(base, ghost),
  big: twMerge(base, big, "active:bg-white active:text-green-900"),
  bigLight: [
    twMerge(
      base,
      big,
      "bg-white text-green-900 active:bg-purple-400 hover:bg-purple-400 active:text-white hover:text-white",
    ),
  ],
  // small: twMerge(base, big, smallBase),
  smallLight: twMerge(
    base,
    big,
    smallBase,
    "bg-white text-green-900 active:bg-purple-400 hover:bg-purple-400 active:text-white hover:text-white",
  ),
  smallDark: twMerge(
    base,
    big,
    smallBase,
    "bg-green-500 text-white active:bg-purple-500 hover:bg-purple-500",
  ),
  iconDark: twMerge(base, iconDark),
  iconLight: twMerge(
    base,
    iconDark,
    "bg-white text-green-900 active:bg-purple-400 hover:bg-purple-400 active:text-white hover:text-white focus-visible:outline-3",
  ),
  link: twMerge(
    base,
    "inline p-0 underline transition-colors duration-200 focus-visible:text-purple-400 hover:text-purple-400 focus-visible:outline-0",
  ),
  disabled: twMerge(base, ["cursor-not-allowed opacity-50"]),
};

export const Button = defineComponent(
  (
    props: {
      type?: "button" | "submit";
      inert?: boolean;
      disabled?: boolean;
      variant?: keyof typeof buttonVariants;
      slot?: string;
      class?: ClassNameValue;
      label?: string;
      onClick?: (e: MouseEvent) => void;
    },
    { slots },
  ) => {
    return () => (
      <button
        type={props.type || "button"}
        inert={props.inert || undefined}
        aria-disabled={props.disabled || undefined}
        class={twMerge(
          buttonVariants[props.variant ?? "default"],
          props.class,
          props.disabled && buttonVariants.disabled,
        )}
        onClick={props.onClick}
        title={props.label}
        aria-label={props.label}
        // @ts-ignore
        slot={props.slot}
      >
        {slots.default?.()}
      </button>
    );
  },
  {
    name: "Button",
    props: ["type", "inert", "variant", "slot", "class", "label", "onClick"],
  },
);
