import { NETWORK_TYPE, log } from "package:/utils";
import { Network } from "@capacitor/network";
import { reactive } from "vue";

type ConnectionType =
  | "bluetooth"
  | "cellular"
  | "ethernet"
  | "mixed"
  | "none"
  | "other"
  | "unknown"
  | "wifi"
  | "wimax";

const networkStatus = reactive({
  useOfflineData: false,
  setUseOfflineData(value: boolean) {
    this.useOfflineData = value;
    log.info("Network change", "useOfflineData", value);
  },
  online: true,
  setOnline(value: boolean) {
    this.online = value;
    log.info("Network change", "online", value);
  },
  type: "unknown",
  setType(value: string) {
    this.type = value;
    log.info("Network change", "type", value);
  },
});

if (typeof window !== "undefined") {
  networkStatus.setOnline(navigator.onLine);

  window.addEventListener("online", () => networkStatus.setOnline(true));
  window.addEventListener("offline", () => networkStatus.setOnline(false));

  // @ts-ignore
  const connection = navigator.connection;
  if (typeof connection !== "undefined") {
    connection.addEventListener("change", () => {
      if (connection.type) networkStatus.setType(connection.type);
      networkStatus.setUseOfflineData(shouldUseOfflineData());
    });
  }

  Network.addListener("networkStatusChange", (status) => {
    networkStatus.setOnline(status.connected);
    networkStatus.setType(status.connectionType);
    networkStatus.setUseOfflineData(shouldUseOfflineData());
  });
}

export function shouldUseOfflineData() {
  if (typeof navigator === "undefined") return false;

  // @ts-ignore
  const connection = navigator.connection;
  if (typeof connection !== "undefined") {
    if (connection.saveData === true) {
      return true;
    }

    if (
      networkStatus.online === true &&
      NETWORK_TYPE[connection.effectiveType] < NETWORK_TYPE["3g"]
    )
      return true;
  }

  return !navigator.onLine;
}

export function useNetwork() {
  return networkStatus;
}
