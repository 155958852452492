<script lang="ts" setup>
import { ErrorPage } from "package:/components/pages/Error";
import { useStaticMeta } from "#imports";

const { error } = defineProps({ error: Object });

useStaticMeta();
</script>

<template>
  <NuxtLayout name="default" bg="purple">
    <ErrorPage :error="error" />
  </NuxtLayout>
</template>
