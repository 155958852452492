import { type Env, getEnv } from "package:/env";
import { useState } from "#app";

// This module can be used to access environment variables in vue components.
export const useEnv = (): Env => {
  const isServer = import.meta.server;

  // TODO: all these are passed to the client
  const serverEnv = useState(() => {
    return getEnv();
  }).value;

  return {
    ...serverEnv,
    API_URL: isServer ? serverEnv.API_URL : serverEnv.PUBLIC_API_URL,
  };
};
