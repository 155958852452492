/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventShortResource } from '../models/EventShortResource';
import type { OpeningTimesResource } from '../models/OpeningTimesResource';
import type { PoiCategoryResource } from '../models/PoiCategoryResource';
import type { PoiShortResource } from '../models/PoiShortResource';
import type { TrailGeoResource } from '../models/TrailGeoResource';
import type { TrailResource } from '../models/TrailResource';
import type { TrailShortResource } from '../models/TrailShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TrailService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param trail The trail slug
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiCategoryResource`
   * @throws ApiError
   */
  public trailsPoiCategories(
    trail: Array<string>,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiCategoryResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}/pois/categories',
      path: {
        'trail': trail,
      },
      query: {
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param trail The trail slug
   * @param hasCategory
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiShortResource`
   * @throws ApiError
   */
  public trailsPois(
    trail: Array<string>,
    hasCategory?: string | null,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}/pois',
      path: {
        'trail': trail,
      },
      query: {
        'has_category': hasCategory,
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param trail The trail slug
   * @returns any `OpeningTimesResource`
   * @throws ApiError
   */
  public trailsState(
    trail: Array<string>,
  ): CancelablePromise<{
    data: OpeningTimesResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}/state',
      path: {
        'trail': trail,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param trail The trail slug
   * @returns any `TrailGeoResource`
   * @throws ApiError
   */
  public trailsGeoData(
    trail: Array<string>,
  ): CancelablePromise<{
    data: TrailGeoResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}/geo-data',
      path: {
        'trail': trail,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param trail The trail slug
   * @returns any Array of `EventShortResource`
   * @throws ApiError
   */
  public trailsEvents(
    trail: Array<string>,
  ): CancelablePromise<{
    data: Array<EventShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}/events',
      path: {
        'trail': trail,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param trail The trail slug
   * @returns any `TrailResource`
   * @throws ApiError
   */
  public trailsShow(
    trail: Array<string>,
  ): CancelablePromise<{
    data: TrailResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails/{trail}',
      path: {
        'trail': trail,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @returns any Array of `TrailShortResource`
   * @throws ApiError
   */
  public trailsIndex(): CancelablePromise<{
    data: Array<TrailShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/trails',
    });
  }
}
