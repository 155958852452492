<script lang="ts" setup>
import { UpdateDialog } from "package:/components/dialogs/UpdateDialog";
import { Toasts, toast } from "package:/components/elements/Toasts";
// import { DevTools } from "package:/components/modules/DevTools";
import { error, info } from "package:/utils";
import { AVAILABLE_LANGS, DEFAULT_LANGUAGE } from "@greentrails/locales/vars.js";
import { ClientOnly, Head, Link, Meta, NuxtLayout } from "#components";
import {
  createError,
  effect,
  onErrorCaptured,
  onMounted,
  showError,
  useEnv,
  useFlags,
  useNetwork,
  useRouter,
  useStaticMeta,
  useTranslations,
} from "#imports";

globalThis.env = useEnv();
globalThis.flags = useFlags();
info(
  "init",
  "\nenv",
  globalThis.env,
  "\nflags",
  globalThis.flags,
  "\nversion",
  globalThis.env.VERSION,
);

useStaticMeta();

onErrorCaptured(err => {
  error("error", err);
  showError(err);
});

const t = useTranslations();
const network = useNetwork();

onMounted(() => {
  effect(() => {
    info("network", "useNetwork", network);

    if (!network.online) {
      toast({
        id: "network.status",
        message: t("misc.offline"),
        time: 1000 * 10,
      });
    } else if (network.useOfflineData === true) {
      toast({
        id: "network.status",
        message: t("misc.slownetwork"),
        time: 1000 * 10,
      });
    }
  });
});

const router = useRouter();
const routeName = router.currentRoute.value?.name as string | undefined;
if (routeName === "index" || routeName?.startsWith("lang")) {
  // validate lang route
  const currLang = router.currentRoute.value?.params.lang as string;
  if (!currLang) {
    router.replace({
      path: `/${DEFAULT_LANGUAGE}`,
    });
  } else if (!AVAILABLE_LANGS.includes(currLang)) {
    showError(
      createError({
        status: 404,
      }),
    );
  }
}
</script>

<template>
  <Head>
    <Meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, viewport-fit=cover, user-scalable=0"
    />

    <Meta name="theme-color" content="#002417" />
    <Meta name="referrer" content="strict-origin" />
    <Meta name="robots" content="index,follow" />
    <Meta name="google" content="notranslate" />
    <Meta name="mobile-web-app-capable" content="yes" />
    <Meta name="apple-touch-fullscreen" content="yes" />
    <Meta name="apple-mobile-web-app-capable" content="yes" />
    <Meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

    <Link rel="icon" href="/favicon.ico" sizes="48x48" />
    <Link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
    <Link rel="apple-touch-icon" href="/apple-touch-icon-180x180.png" />
    <Link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="/apple-splash-portrait-light-1536x2048.png"
    />
    <Link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="/apple-splash-landscape-light-2048x1536.png"
    />
  </Head>

  <!-- <NuxtPwaManifest /> -->

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <ClientOnly>
    <Toasts />
    <UpdateDialog />
  </ClientOnly>
</template>
