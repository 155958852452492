import type {
  AreaResource,
  AreaShortResource,
  CancelablePromise,
  TrailResource,
} from "@greentrails/api";
import logger from "@luckydye/log";
import { SentryWriteStream } from "./sentry.log";

export const ERROR_CODE = {
  "offline-storage-init": 1,
  "offline-storage-added": 2,
  "offline-storage-removed": 3,
};

export const ROUTE_VIEW = {
  PREVIEW: "preview",
  DETAIL: "detail",
} as const;

export enum DetailType {
  Poi = "poi",
  Trail = "trail",
  Round = "round",
  Area = "area",
}

globalThis.JS_LOG = import.meta.env.JS_LOG || import.meta.env.PUBLIC_JS_LOG || "error";

export const log = logger()
  .time(false)
  .trace()
  .prefix("app")
  .pipeTo(new SentryWriteStream());

export const { info, error, warn, debug } = log;

export class Tracking {
  /**
   * Track Event
   * @example
   * trackEvent('Contact', 'Email Link Click', 'name@example.com');
   */
  static trackEvent(category: string, action: string, name?: string, value?: number) {
    window._paq = window._paq || [];
    const data: Array<string | number> = ["trackEvent", category, action];
    if (name !== undefined) {
      data.push(name);
    }
    if (value !== undefined) {
      data.push(value);
    }
    window._paq.push(data);
  }

  static push(data: any) {
    window._paq = window._paq || [];
    window._paq.push(data);
  }

  /**
   * Enable or disable opt-out
   */
  static optOut(state: boolean) {
    Tracking.push([!state ? "forgetUserOptOut" : "optUserOut"]);
  }
}

export const debugGrid = `
  <div class="content grid grid-cols-12 gap-4 absolute z-50 h-screen pointer-events-none opacity-25">
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
  <div class="bg-red"></div>
</div>`;

export function distanceToString(distance: number, elevation = false): string {
  const numberFormat = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
  });

  if (distance < 1000 || elevation) {
    return `${Math.floor(distance)} m`;
  }
  return `${numberFormat.format(Number((distance / 1000).toFixed(2)))} km`;
}

export function fuzzError() {
  if (import.meta.env.DEV) {
    if (Math.random() > 0.5) {
      throw new Error("Fuzzing error");
    }
  }
}

export async function areasIndexFiltered(
  response: CancelablePromise<{
    data: Array<AreaShortResource>;
    bounds: any[];
  }>,
) {
  const data = await response;
  for (const area of data.data) {
    area.trails = area.trails.filter((trail) => trail.type === "trail");
  }
  return response;
}

export async function areaShowFiltered(
  response: CancelablePromise<{
    data: AreaResource;
  }>,
) {
  const data = await response;
  data.data.trails = data.data.trails.filter((trail) => trail.type === "trail");
  return response;
}

export async function trailShowFiltered(
  response: CancelablePromise<{
    data: TrailResource;
  }>,
) {
  const data = await response;
  if (data.data.type !== "trail") {
    throw new Error("Invalid trail type");
  }
  return response;
}

export const NETWORK_TYPE = {
  "slow-2g": 0,
  "2g": 1,
  "3g": 2,
  "4g": 3,
};

// TODO: This is duplicated from ./composables/useNetwork.ts
const networkStatus = {
  online: true,
  setOnline(value: boolean) {
    this.online = value;
    log.info("Network change", "online", value);
  },
};

if (typeof window !== "undefined") {
  networkStatus.setOnline(navigator.onLine);

  window.addEventListener("online", () => networkStatus.setOnline(true));
  window.addEventListener("offline", () => networkStatus.setOnline(false));

  // Network.addListener("networkStatusChange", (status) => {
  //   networkStatus.setOnline(status.connected);
  // });
}

export function shouldUseOfflineData() {
  if (typeof navigator === "undefined") return false;

  // @ts-ignore
  const connection = navigator.connection;
  if (typeof connection !== "undefined") {
    if (connection.saveData === true) {
      return true;
    }

    if (
      networkStatus.online === true &&
      NETWORK_TYPE[connection.effectiveType] <= NETWORK_TYPE["3g"]
    )
      return true;
  }

  return !navigator.onLine;
}

export function parseVersion(version: string) {
  const parts = version.split("-");
  const semver = parts[0]?.split(".");
  const dev = parts[1];
  const build = dev?.split(".")[1];

  return {
    name: version,
    major: +(semver?.[0] || 0),
    minor: +(semver?.[1] || 0),
    patch: +(semver?.[2] || 0),
    isDev: !!parts[1]?.startsWith("dev"),
    build: build ? +build : undefined,
  };
}

export function download(url: string, name?: string) {
  const a = document.createElement("a");
  a.download = name || "Green Trails";
  a.href = url;
  a.click();
}
