import { log } from "package:/utils";
import { BrowserTracing, replayIntegration } from "@sentry/browser";
import { vueRouterInstrumentation } from "@sentry/vue/esm/router";
import { init } from "@sentry/vue/esm/sdk";
import { defineNuxtPlugin, useEnv, useRouter } from "#imports";

// see https://www.lichter.io/articles/nuxt3-sentry-recipe/
export default defineNuxtPlugin((app) => {
  const env = useEnv();
  const router = useRouter();

  if (!import.meta.env.DEV) {
    init({
      app: app.vueApp,
      // debug: true,
      dsn: env.SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
        }),
        replayIntegration(),
      ],
      environment: env.ENVIROMENT,
      release: `${env.NAME}-${env.VERSION}`,
      tracesSampleRate: 0.2,
      tracePropagationTargets: ["localhost", env.SITE_URL],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    });
  } else {
    log.info("Sentry is disabled in dev mode");
  }
});
