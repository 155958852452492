import { log } from "package:/utils";
import { onMounted, onUnmounted, ref, useRouter } from "#imports";

const callbacks = new Set<() => any>();

export function commitHistory() {
  log.debug("before navigate", history.state);

  applyCallbacks();
}

function applyCallbacks() {
  for (const cb of callbacks) {
    const state = {
      ...history.state,
      ...cb(),
      href: location.href,
    };
    history.replaceState(state, "");
  }
}

/**
 * A hook which can be used to store component state in the global browser history.
 */
export function useHistory<T extends {}>(cb: () => T) {
  const state = ref<
    T & {
      back: null | string;
      replaced?: boolean;
    }
  >();

  const router = useRouter();

  // router.before: is called after history.replaceState, so we can't use it to store state before navigation

  router.afterEach(async () => {
    state.value = history.state;

    log.debug("after navigate", history.state);
  });

  onMounted(() => {
    state.value = history.state;
    callbacks.add(cb);
  });

  onUnmounted(() => {
    callbacks.delete(cb);
  });

  return state;
}
