import { error } from "package:/utils";
import { ApiError, type GreenTrailsApiClient } from "@greentrails/api";
import {
  type NetworkMode,
  onlineManager,
  useQuery,
  useInfiniteQuery,
} from "@tanstack/vue-query";
import { effect } from "vue";
import { useNuxtApp, useRequestEvent } from "#app";
import { ref } from "#imports";
import { useNetwork } from "./useNetwork";

export function useApiClient() {
  return useNuxtApp().$apiClient;
}

/**
 * Composable to fetch api data.
 */
export function useApi<T>(
  fetchCallback: (apiClient: GreenTrailsApiClient) => Promise<T>,
  cacheKey?: string,
  online?: boolean,
) {
  const client = useApiClient();
  const network = useNetwork();
  const networkMode = ref<NetworkMode>(
    online && network.online ? "online" : "offlineFirst",
  );

  const queryKey = [cacheKey, fetchCallback.toString()];

  effect(() => {
    onlineManager.setOnline(network.online);
  });

  const {
    isPending,
    data,
    error: err,
    suspense,
  } = useQuery<T>({
    staleTime: process.browser ? 1000 * 60 : 0,
    gcTime: process.browser ? 1000 * 60 * 60 : 0,
    refetchOnReconnect: true,
    networkMode,
    retry(failureCount, error) {
      if (error instanceof ApiError) {
        return error.status !== 404;
      }
      return failureCount < 3;
    },
    queryKey,
    queryFn: () => fetchCallback(client),
  });

  const req = useRequestEvent();

  effect(() => {
    if (err.value) {
      error(
        err.value.message,
        "headers",
        req?.req.headers,
        "key",
        queryKey,
        "cause",
        err.value.cause,
      );
    }
  });

  return {
    pending: isPending,
    error: err,
    data: data,
    suspense: suspense,
  };
}

/**
 * Composable to fetch api data.
 */
export function useApiInfinite<T>(
  fetchCallback: (apiClient: GreenTrailsApiClient, page, perPage) => Promise<T>,
  perPage = 15,
  cacheKey?: string,
  online?: boolean,
) {
  const client = useApiClient();
  const network = useNetwork();
  const networkMode = ref<NetworkMode>(
    online && network.online ? "online" : "offlineFirst",
  );

  const queryKey = [cacheKey, fetchCallback.toString()];

  effect(() => {
    onlineManager.setOnline(network.online);
  });

  const {
    data,
    error: err,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    isError,
    suspense,
  } = useInfiniteQuery({
    staleTime: process.browser ? 1000 * 60 : 0,
    gcTime: process.browser ? 1000 * 60 * 60 : 0,
    refetchOnReconnect: true,
    networkMode,
    queryKey,
    queryFn: (data) => {
      return fetchCallback(client, data.pageParam, perPage);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.links.next ? lastPage.meta.current_page + 1 : lastPage.links.next;
    },
  });

  const req = useRequestEvent();

  effect(() => {
    if (err.value) {
      error(
        err.value.message,
        "headers",
        req?.req.headers,
        "key",
        queryKey,
        "cause",
        err.value.cause,
      );
    }
  });

  return {
    pending: isPending,
    error: err,
    data: data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
    suspense: suspense,
  };
}
