import { log } from "package:/utils";
import { AVAILABLE_LANGS, DEFAULT_LANGUAGE } from "@greentrails/locales/vars";
import { ref } from "vue";
import { useRequestHeader, useRouter } from "#imports";

function parseAcceptLangHeader(hdr: string) {
  const pairs = hdr.split(",");
  const result: string[][] = [];
  for (let i = 0; i < pairs.length; i++) {
    const [part1, part2] = pairs[i]?.split(";") || [];
    if (part1 && part2) {
      const n = part2.split("=")[1];
      if (n) result.push([part1, n]);
    } else if (part1) {
      result.push([part1, "1"]);
    }
  }
  return result;
}

// TODO: make this not dependent on a vue instance
export function useLanguage() {
  const router = useRouter();
  const langParam = router.currentRoute.value?.params.lang as string | undefined;
  let lang = AVAILABLE_LANGS.includes(langParam || "")
    ? langParam || DEFAULT_LANGUAGE
    : DEFAULT_LANGUAGE;

  try {
    const header = useRequestHeader("accept-language");
    if (header) {
      const langs = parseAcceptLangHeader(header);
      const first_lang = langs[0]?.[0];
      const l = first_lang?.split("-")[0] ?? lang;

      if (AVAILABLE_LANGS.includes(l)) {
        lang = l;
      }
    }
  } catch (err) {
    log.error("error", err);
  }

  return ref(lang);
}
