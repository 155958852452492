import { default as _91slug_93GvnMWz4D6uMeta } from "/frontend/web/pages/[lang]/[slug].vue?macro=true";
import { default as _91slug_93fSjqhYu9I9Meta } from "/frontend/web/pages/[lang]/events/[slug].vue?macro=true";
import { default as indexBGuAZXIAFvMeta } from "/frontend/web/pages/[lang]/events/index.vue?macro=true";
import { default as indexjQTaIzajVcMeta } from "/frontend/web/pages/[lang]/index.vue?macro=true";
import { default as _91detail_93ITiB0dAEm4Meta } from "/frontend/web/pages/[lang]/map/[area]/[type]/[detail].vue?macro=true";
import { default as indexNm08eLtjZKMeta } from "/frontend/web/pages/[lang]/map/[area]/index.vue?macro=true";
import { default as indexA1i8QkVaT9Meta } from "/frontend/web/pages/[lang]/map/index.vue?macro=true";
import { default as _91slug_93J7IMDi9hJdMeta } from "/frontend/web/pages/[lang]/news/[slug].vue?macro=true";
import { default as indexUq16nCegwrMeta } from "/frontend/web/pages/[lang]/news/index.vue?macro=true";
import { default as offlineSjnMq4tFK5Meta } from "/frontend/web/pages/[lang]/offline.tsx?macro=true";
import { default as profileVatJhj3VJ2Meta } from "/frontend/web/pages/[lang]/profile.vue?macro=true";
import { default as reportHrthAMA7DEMeta } from "/frontend/web/pages/[lang]/report.tsx?macro=true";
import { default as savedCrHt0YhWSjMeta } from "/frontend/web/pages/[lang]/saved.tsx?macro=true";
import { default as appstoresUCx331XZwiMeta } from "/frontend/web/pages/appstores.vue?macro=true";
import { default as _91id_93FkIpJ6hI8dMeta } from "/frontend/web/pages/audio/[id].vue?macro=true";
import { default as indexDVNTnszw2iMeta } from "/frontend/web/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93GvnMWz4D6uMeta?.name ?? "lang-slug",
    path: _91slug_93GvnMWz4D6uMeta?.path ?? "/:lang()/:slug()",
    meta: _91slug_93GvnMWz4D6uMeta || {},
    alias: _91slug_93GvnMWz4D6uMeta?.alias || [],
    redirect: _91slug_93GvnMWz4D6uMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fSjqhYu9I9Meta?.name ?? "lang-events-slug",
    path: _91slug_93fSjqhYu9I9Meta?.path ?? "/:lang()/events/:slug()",
    meta: _91slug_93fSjqhYu9I9Meta || {},
    alias: _91slug_93fSjqhYu9I9Meta?.alias || [],
    redirect: _91slug_93fSjqhYu9I9Meta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBGuAZXIAFvMeta?.name ?? "lang-events",
    path: indexBGuAZXIAFvMeta?.path ?? "/:lang()/events",
    meta: indexBGuAZXIAFvMeta || {},
    alias: indexBGuAZXIAFvMeta?.alias || [],
    redirect: indexBGuAZXIAFvMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQTaIzajVcMeta?.name ?? "lang",
    path: indexjQTaIzajVcMeta?.path ?? "/:lang()",
    meta: indexjQTaIzajVcMeta || {},
    alias: indexjQTaIzajVcMeta?.alias || [],
    redirect: indexjQTaIzajVcMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93ITiB0dAEm4Meta?.name ?? "lang-map-area-type-detail",
    path: _91detail_93ITiB0dAEm4Meta?.path ?? "/:lang()/map/:area()/:type()/:detail()",
    meta: _91detail_93ITiB0dAEm4Meta || {},
    alias: _91detail_93ITiB0dAEm4Meta?.alias || [],
    redirect: _91detail_93ITiB0dAEm4Meta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/map/[area]/[type]/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexNm08eLtjZKMeta?.name ?? "lang-map-area",
    path: indexNm08eLtjZKMeta?.path ?? "/:lang()/map/:area()",
    meta: indexNm08eLtjZKMeta || {},
    alias: indexNm08eLtjZKMeta?.alias || [],
    redirect: indexNm08eLtjZKMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/map/[area]/index.vue").then(m => m.default || m)
  },
  {
    name: indexA1i8QkVaT9Meta?.name ?? "lang-map",
    path: indexA1i8QkVaT9Meta?.path ?? "/:lang()/map",
    meta: indexA1i8QkVaT9Meta || {},
    alias: indexA1i8QkVaT9Meta?.alias || [],
    redirect: indexA1i8QkVaT9Meta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/map/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J7IMDi9hJdMeta?.name ?? "lang-news-slug",
    path: _91slug_93J7IMDi9hJdMeta?.path ?? "/:lang()/news/:slug()",
    meta: _91slug_93J7IMDi9hJdMeta || {},
    alias: _91slug_93J7IMDi9hJdMeta?.alias || [],
    redirect: _91slug_93J7IMDi9hJdMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexUq16nCegwrMeta?.name ?? "lang-news",
    path: indexUq16nCegwrMeta?.path ?? "/:lang()/news",
    meta: indexUq16nCegwrMeta || {},
    alias: indexUq16nCegwrMeta?.alias || [],
    redirect: indexUq16nCegwrMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/news/index.vue").then(m => m.default || m)
  },
  {
    name: offlineSjnMq4tFK5Meta?.name ?? "lang-offline",
    path: offlineSjnMq4tFK5Meta?.path ?? "/:lang()/offline",
    meta: offlineSjnMq4tFK5Meta || {},
    alias: offlineSjnMq4tFK5Meta?.alias || [],
    redirect: offlineSjnMq4tFK5Meta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/offline.tsx").then(m => m.default || m)
  },
  {
    name: profileVatJhj3VJ2Meta?.name ?? "lang-profile",
    path: profileVatJhj3VJ2Meta?.path ?? "/:lang()/profile",
    meta: profileVatJhj3VJ2Meta || {},
    alias: profileVatJhj3VJ2Meta?.alias || [],
    redirect: profileVatJhj3VJ2Meta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/profile.vue").then(m => m.default || m)
  },
  {
    name: reportHrthAMA7DEMeta?.name ?? "lang-report",
    path: reportHrthAMA7DEMeta?.path ?? "/:lang()/report",
    meta: reportHrthAMA7DEMeta || {},
    alias: reportHrthAMA7DEMeta?.alias || [],
    redirect: reportHrthAMA7DEMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/report.tsx").then(m => m.default || m)
  },
  {
    name: savedCrHt0YhWSjMeta?.name ?? "lang-saved",
    path: savedCrHt0YhWSjMeta?.path ?? "/:lang()/saved",
    meta: savedCrHt0YhWSjMeta || {},
    alias: savedCrHt0YhWSjMeta?.alias || [],
    redirect: savedCrHt0YhWSjMeta?.redirect,
    component: () => import("/frontend/web/pages/[lang]/saved.tsx").then(m => m.default || m)
  },
  {
    name: appstoresUCx331XZwiMeta?.name ?? "appstores",
    path: appstoresUCx331XZwiMeta?.path ?? "/appstores",
    meta: appstoresUCx331XZwiMeta || {},
    alias: appstoresUCx331XZwiMeta?.alias || [],
    redirect: appstoresUCx331XZwiMeta?.redirect,
    component: () => import("/frontend/web/pages/appstores.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FkIpJ6hI8dMeta?.name ?? "audio-id",
    path: _91id_93FkIpJ6hI8dMeta?.path ?? "/audio/:id()",
    meta: _91id_93FkIpJ6hI8dMeta || {},
    alias: _91id_93FkIpJ6hI8dMeta?.alias || [],
    redirect: _91id_93FkIpJ6hI8dMeta?.redirect,
    component: () => import("/frontend/web/pages/audio/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDVNTnszw2iMeta?.name ?? "index",
    path: indexDVNTnszw2iMeta?.path ?? "/",
    meta: indexDVNTnszw2iMeta || {},
    alias: indexDVNTnszw2iMeta?.alias || [],
    redirect: indexDVNTnszw2iMeta?.redirect,
    component: () => import("/frontend/web/pages/index.vue").then(m => m.default || m)
  }
]