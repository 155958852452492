import { defineComponent, provide, ref, effect } from "#imports";
import "./Icon.css";
import { twMerge } from "tailwind-merge";
import "./Animation";

const iconMap = import.meta.glob<BufferImport>("../../assets/icons/*.(svg|riv)", {
  eager: true,
  query: "?buffer",
});

// Should be kept in sync with all files in /assets/icons/*.(svg|riv)
const icon_names = [
  "unknown",
  "alert",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "arrow-gain",
  "arrow-loss",
  "car",
  "close",
  "checkmark",
  "download",
  "download-small",
  "downloading",
  "expand-down",
  "expand-up",
  "facebook",
  "filter",
  "instagram",
  "layers",
  "list-item",
  "linkedin",
  "mail",
  "map",
  "marker-end",
  "marker-start",
  "maximize",
  "menu",
  "minus",
  "phone",
  "position",
  "plus",
  "scan",
  "share",
  "star",
  "state-restricted",
  "state-arrow",
  "state-open",
  "state-closed",
  "state-unknown",
  "trail",
  "youtube",
  "map-route-marker",
  "poi-restaurant",
  "poi-bike_rental",
  "poi-culture",
  "poi-nature",
  "poi-parking",
  "poi-rescue_point",
  "poi-toilet",
  "poi-workshop",
  "poi-misc",
  "poi-big-restaurant",
  "poi-big-bike_rental",
  "poi-big-culture",
  "poi-big-nature",
  "poi-big-parking",
  "poi-big-rescue_point",
  "poi-big-toilet",
  "poi-big-workshop",
  "poi-big-misc",
] as const;

export type IconName = (typeof icon_names)[number];

export const icons = new Map(
  Object.keys(iconMap).map((name) => {
    const id = name.split("/assets/icons/")[1]?.split(".")[0];
    return [id, iconMap[name]?.default];
  }),
);

interface Props {
  name: IconName;
  class?: string;
  size?: number;
  inputs?: Record<string, number | boolean>;
}

export const Icon = defineComponent(
  (props: Props) => {
    const rive = ref();

    const updateInputs = () => {
      const keyValues = props.inputs || {};

      for (const [key, value] of Object.entries(keyValues)) {
        const input = rive.value?.input(key);
        if (input) {
          if (typeof value === "boolean") {
            input.asBoolean().value = value;
          } else if (typeof value === "number") {
            input.asNumber().value = value;
          }
        }
      }
    };

    effect(() => updateInputs());

    provide("rive", {
      trigger(name: string) {
        rive.value?.trigger(name);
      },
      input(name: string) {
        rive.value?.input(name);
      },
    });

    return () => {
      const key = icons.has(props.name) ? props.name : "unknown";
      const icon = icons.get(key);

      if (!icon) throw new Error(`Icon ${props.name} not found`);

      return icon.type === "riv" ? (
        <a-animation
          class={twMerge(props.class, "icon")}
          data-icon={key}
          onLoad={() => updateInputs()}
          ref={rive}
          src={icon.uri}
          width={props.size ?? 32}
          height={props.size ?? 32}
          autoplay
        />
      ) : (
        <span data-icon={key} class={twMerge(props.class, "icon")} v-html={icon.text} />
      );
    };
  },
  {
    name: "Icon",
    props: ["name", "size", "class", "inputs"],
  },
);
