import { commitHistory } from "package:/composables/useHistory";
import { type ClassNameValue, twMerge } from "tailwind-merge";
import type { RouteLocationNamedRaw, RouteLocationRaw } from "vue-router";
import { NuxtLink } from "#components";
import { defineComponent, navigateTo } from "#imports";

export function navigate(to: RouteLocationRaw | undefined | null) {
  commitHistory();
  return navigateTo(to);
}

const linkVariants = {
  plain: ["hover:text-purple-400"],
  inline: ["hover:text-purple-400 underline decoration-1 underline-offset-4"],
};

/**
 * A wrapper around NuxtLink that allows for custom click handling.
 */
export const Link = defineComponent(
  (
    props: {
      class?: ClassNameValue;
      replace?: boolean;
      /**
       * to
       */
      to?: RouteLocationNamedRaw;
      /**
       * href
       */
      href?: string;
      /**
       * Overwrite language root
       */
      lang?: string;
      label?: string;
      rel?: string;
      variant?: keyof typeof linkVariants;
      /**
       * Callback triggered right before navigation is initiated.
       */
      onClick?: (e: MouseEvent) => void;
    },
    { slots },
  ) => {
    const to = props.to;

    return () => (
      <NuxtLink
        custom={true}
        href={props.href}
        to={to}
        rel={props.rel}
        replace={props.replace}
      >
        {{
          default: (args) => {
            const {
              href,
              // navigate,
              route,
              rel,
              target,
              isExternal,
              isActive,
              isExactActive,
            } = args;
            return (
              <a
                href={href}
                onClick={(e) => {
                  props.onClick?.(e);

                  if (!isExternal) {
                    navigate(route);
                    e.preventDefault();
                  }
                }}
                data-active={isActive || undefined}
                data-active-exact={isExactActive || undefined}
                target={isExternal && route?.href?.startsWith("http") ? "_blank" : target}
                rel={
                  [isExternal && "noreferrer", rel].filter(Boolean).join(" ") || undefined
                }
                class={twMerge(
                  "cursor-pointer transition-colors duration-200",
                  props.variant && linkVariants[props.variant],
                  props.class,
                )}
                aria-label={props.label}
              >
                {slots.default?.()}
              </a>
            );
          },
        }}
      </NuxtLink>
    );
  },
  {
    props: ["class", "lang", "onClick", "to", "href", "replace", "label", "variant"],
    inheritAttrs: false,
  },
);
