import { type DBSchema, openDB } from "idb";
import { GreenTrailsApiClient } from "./index";
import { debug, error, info } from "./util";

/**
 * Store of whats (supposed to be) available offline
 */
export interface OfflineStorage {
  has(key: string): Promise<boolean>;
  add(key: string): Promise<void>;
  delete(key: string): Promise<void>;
  list(): Promise<string[]>;
  get(key: string): Promise<any>;
  available(key: string): Promise<any>;
  store(key: string, area: string, data: any): Promise<void>;
}

let jobIdCounter = 0;

interface JobContext<T = any> {
  data?: T;
  enqueue: (job: Job) => number;
}

/**
 * A Job is just a promise with some metadata.
 */
class Job {
  public id = ++jobIdCounter;
  public parentId?: number;
  public context?: JobContext;
  public status: "idle" | "running" | "canceled" | "stalled" | "done" = "idle";

  constructor(private fn: (cx: JobContext) => Promise<void>) {}

  execute(cx: JobContext) {
    this.context = cx;
    this.status = "running";
    return this.fn(cx);
  }

  isCanceled() {
    return this.status === "canceled";
  }
}

/**
 * A Promise based job queue.
 * Jobs are executed in order, Jobs can have a parent job.
 */
class JobQueue {
  private queue: Set<Job> = new Set();
  private runningJobs: Set<Job> = new Set();
  private stalledJobs: Set<Job> = new Set();
  private concurency = 1;

  constructor() {
    info("JobQueue", this);
  }

  progress(id: number) {
    const job = this.job(id);
    const childJobs = this.jobs(id);
    const context = job?.context;

    if (context) {
      return 1 - childJobs.length / context.data.childJobCount;
    }

    return -1;
  }

  /**
   * Get all jobs for a given id, including child jobs
   */
  jobs(id: number) {
    const jobs = [...this.queue, ...this.runningJobs, ...this.stalledJobs];
    return jobs.filter((job) => job.id === id || job.parentId === id);
  }

  /**
   * Get a job by id
   */
  job(id: number) {
    return this.jobs(id).find((job) => job.id === id);
  }

  /**
   * Get all child jobs for a given id
   */
  childJobs(id: number) {
    return this.jobs(id).filter((job) => job.parentId === id);
  }

  /**
   * Cancel a job and all of its child jobs
   */
  cancel(id: number) {
    const jobs = this.jobs(id);

    const allJobs = [...this.queue, ...this.runningJobs, ...this.stalledJobs];

    info("cancel", "job id", id, "all jobs", allJobs);

    for (const job of jobs) {
      job.status = "canceled";
      this.queue.delete(job);
    }

    info("canceled", "all jobs", allJobs);
  }

  enqueue(job: Job) {
    this.queue.add(job);
    debug("enqueue", "job", job, "queue length", this.queue.size);
    this.flush();
    return job.id;
  }

  /**
   * Wait for a job to finish, including all child jobs
   */
  block(id: number, callback?: (done: boolean, progress: number) => void) {
    return new Promise<void>((ok, err) => {
      const int = setInterval(() => {
        const jobs = this.jobs(id);
        const done = jobs.length === 0;

        const progress = this.progress(id);

        try {
          callback?.(done, progress);
        } catch (error) {
          clearInterval(int);
          err(error);
          return;
        }

        if (!done) {
          return;
        }

        clearInterval(int);

        ok();
      }, 150);
    });
  }

  /**
   * Process next entry in queue
   */
  flush() {
    // cleaup stalled jobs
    for (const stalledJob of this.stalledJobs) {
      const childJobs = this.childJobs(stalledJob.id);

      if (childJobs.length === 0) {
        if (stalledJob.status !== "canceled") {
          stalledJob.status = "done";
        }
        this.stalledJobs.delete(stalledJob);
      }
    }

    if (this.runningJobs.size >= this.concurency) return;

    const job: Job = this.queue.values().next().value;

    if (job) {
      debug(
        "flush",
        "job",
        job.id,
        "queue",
        [...this.queue].map((job) => `${job.parentId || 0}.${job.id}`),
      );

      this.queue.delete(job);
      this.runningJobs.add(job);

      const context: JobContext = {
        data: {
          childJobCount: 0,
        },
        enqueue: (childJob: Job) => {
          childJob.parentId = job.id;
          context.data.childJobCount++;
          return this.enqueue(childJob);
        },
      };

      job
        .execute(context)
        .catch((err) => {
          error("job error", "Err:", err);
        })
        .finally(() => {
          const childJobs = this.childJobs(job.id);

          if (childJobs.length !== 0) {
            job.status = "stalled";
            this.stalledJobs.add(job);
          } else {
            job.status = "done";
          }

          this.runningJobs.delete(job);

          this.flush();
        });
    }
  }
}

interface DBV1 extends DBSchema {
  "offline-available": {
    value: {
      added?: Date;
      downloaded: boolean;
      error: boolean;
      progress?: number;
    };
    key: string;
  };
  "offline-storage": {
    value: {
      area: string;
      key: string;
      data: Blob;
      date: Date;
    }; // blob
    key: string; // url path
  };
}

/**
 * Implemnetation of said store
 */
export class IndexOfflineStorage extends JobQueue implements OfflineStorage, JobContext {
  db = openDB<DBV1>("offline-storage-v1", 1, {
    upgrade(db) {
      db.createObjectStore("offline-available");
      db.createObjectStore("offline-storage");
    },
  });

  onchange?: () => void;

  apiClient: GreenTrailsApiClient;

  constructor(options: {
    baseUrl: string;
    assetsUrl?: string;
    lang?: string;
  }) {
    super();

    this.apiClient = new GreenTrailsApiClient(options);
  }

  public async init() {
    return new Promise<void>((resolve, reject) => {
      // wait for idle
      setTimeout(async () => {
        try {
          this.downloadAreaIndex();
          const list = await this.list();

          await Promise.all(
            list.map(async (area) => {
              const status = await this.available(area);

              if (status?.downloaded === false) {
                info("downloading", area, status);
                await this.add(area);
              }
            }),
          );
        } catch (err) {
          reject(err);
        }

        resolve();
      }, 1000);
    });
  }

  public async has(key: string) {
    const db = await this.db;
    const c = await db.count("offline-available", key);
    return c > 0;
  }

  public async available(key: string) {
    const db = await this.db;
    return await db.get("offline-available", key);
  }

  public async list() {
    const db = await this.db;
    const keys = await db.getAllKeys("offline-available");
    return keys;
  }

  private runningAddJobs = new Map<string, number>();

  public async add(key: string) {
    const db = await this.db;

    db.put(
      "offline-available",
      { added: undefined, downloaded: false, error: false },
      key,
    );

    debug("enqeue download", key);

    const cancel = () => {
      return db.delete("offline-available", key);
    };

    const job = new Job(async (cx) => {
      try {
        await this.downloadArea(key, cx);
      } catch (err) {
        cancel();
        throw err;
      }
    });

    const id = this.enqueue(job);

    this.runningAddJobs.set(key, id);

    await this.block(id, (done, progress) => {
      debug("job progress", "id", id, "prog", progress);

      db.put(
        "offline-available",
        { added: undefined, downloaded: false, error: false, progress: progress },
        key,
      ).then(() => {
        this.onchange?.();
      });
    });

    this.runningAddJobs.delete(key);

    info("flush", "DONE", job);

    if (job.isCanceled()) {
      info("flush", "canceled", key);
      await cancel();
    } else {
      await db.put(
        "offline-available",
        { added: new Date(), downloaded: true, error: false },
        key,
      );
    }

    this.onchange?.();
  }

  public async delete(key: string) {
    const db = await this.db;
    db.delete("offline-available", key);

    const id = this.runningAddJobs.get(key);
    if (id) this.cancel(id);

    this.enqueue(
      new Job(async () => {
        const list = await db.getAll("offline-storage");

        await Promise.all(
          list.map(async (entry) => {
            if (entry.area === key) {
              await db.delete("offline-storage", entry.key);
            }
          }),
        );
      }),
    );
  }

  public async get(key: string) {
    const db = await this.db;
    const count = await db.count("offline-storage", key);
    if (count > 0) {
      return await db.get("offline-storage", key);
    }
    return;
  }

  public async store(key: string, area: string, data: Blob) {
    const db = await this.db;
    await db.put(
      "offline-storage",
      {
        key: key,
        data: data,
        area: area,
        date: new Date(),
      },
      key,
    );
  }

  //
  // Download functions
  //
  private downloadAreaIndex() {
    const storeGlobal = (res) => {
      this.store(res.__path, "", new Blob([JSON.stringify(res)]));
      debug("download", "res", res);
      return res;
    };

    this.enqueue(
      new Job(async () => {
        await this.apiClient.page.pagesNavigation().then(storeGlobal);
      }),
    );

    this.enqueue(
      new Job(async () => {
        await this.apiClient.area.areasIndex().then(storeGlobal);
      }),
    );

    this.enqueue(
      new Job(async () => {
        await this.apiClient.poi.poisCategories().then(storeGlobal);
      }),
    );

    this.enqueue(
      new Job(async () => {
        await this.apiClient.poi.poisIndex(null, null, "1").then(storeGlobal);
      }),
    );
  }

  /**
   * Request the area and all of its leaves and store them in the offline storage
   * @throws Error
   */
  private async downloadArea(slug: string, cx: JobContext) {
    debug("download", "area", slug);

    // TODO: check the existence and date added before requesting new resources

    const area = await this.apiClient.area.areasShow(slug).then(async (res) => {
      this.store(
        res.__path.replace("{area}", slug),
        slug,
        new Blob([JSON.stringify(res)]),
      );

      debug("download", "res", res);

      return res;
    });

    cx.enqueue(
      new Job(async () => {
        const res = await this.apiClient.area.areasGeoData(slug);
        this.store(
          res.__path.replace("{area}", slug),
          slug,
          new Blob([JSON.stringify(res)]),
        );
      }),
    );

    cx.enqueue(
      new Job(async () => {
        const res = await this.apiClient.area.areasPois(slug);
        this.store(
          res.__path.replace("{area}", slug),
          slug,
          new Blob([JSON.stringify(res)]),
        );
      }),
    );

    area.data.images.map((image) =>
      cx.enqueue(new Job(async () => await this.downloadImage(slug, image.url))),
    );

    area.data.rounds.map((round) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadRound(slug, round.slug, cx);
        }),
      ),
    );

    area.data.trails.map((trail) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadTrail(slug, trail.slug, cx);
        }),
      ),
    );

    area.data.pois.map((poi) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadPoi(slug, poi.slug, cx);
        }),
      ),
    );
  }

  private async downloadImage(area: string, src: string) {
    return await fetch(
      `${this.apiClient.options.assetsUrl}/renditions${src}?w=420&fm=webp&q=65`,
    ).then(async (res) => {
      this.store(src, area, await res.blob());
    });
  }

  private async downloadRound(area: string, slug: string, cx: JobContext) {
    debug("download", "round", slug);

    const round = await this.apiClient.round.roundsShow(slug).then((res) => {
      this.store(
        res.__path.replace("{round}", slug),
        area,
        new Blob([JSON.stringify(res)]),
      );
      debug("download", "res", res);
      return res;
    });

    round.data.images.map((image) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadImage(area, image.url);
        }),
      ),
    );

    cx.enqueue(
      new Job(async () => {
        const res = await this.apiClient.round.roundsGeoData(slug);
        this.store(
          res.__path.replace("{round}", slug),
          area,
          new Blob([JSON.stringify(res)]),
        );
        debug("download", "res", res);
      }),
    );
  }

  private async downloadTrail(area: string, slug: string, cx: JobContext) {
    const trail = await this.apiClient.trail.trailsShow(slug).then((res) => {
      this.store(
        res.__path.replace("{trail}", slug),
        area,
        new Blob([JSON.stringify(res)]),
      );
      return res;
    });

    trail.data.images.map((image) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadImage(area, image.url);
        }),
      ),
    );

    cx.enqueue(
      new Job(async () => {
        const res = await this.apiClient.trail.trailsGeoData(slug);
        this.store(
          res.__path.replace("{trail}", slug),
          area,
          new Blob([JSON.stringify(res)]),
        );
      }),
    );
  }

  private async downloadPoi(area: string, slug: string, cx: JobContext) {
    debug("download", "round", slug);

    const poi = await this.apiClient.poi.poisShow(slug).then((res) => {
      const path = res.__path.replace("{poi}", slug);
      this.store(path, area, new Blob([JSON.stringify(res)]));
      debug("download", "res", res);

      return res;
    });

    poi.data.images.map((image) =>
      cx.enqueue(
        new Job(async () => {
          await this.downloadImage(area, image.url);
        }),
      ),
    );
  }
}
