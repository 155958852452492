import { Capacitor } from "@capacitor/core";
import { type LngLatBounds, config as MapTilerConfig } from "@maptiler/sdk";

export function isNativePlattform() {
  return Capacitor.isNativePlatform();
}

export interface MapOptions {
  container?: HTMLElement | string;
  bounds?: LngLatBounds | [number, number];
  navigationControl?: boolean;
  geolocateControl?: boolean;
  terrainControl?: boolean;
  scaleControl?: boolean;
  fullscreenControl?: boolean;
  interactive?: boolean;
  zoomControl?: boolean;
}

export const config = MapTilerConfig;

// main stuff
export async function init(options: MapOptions = {}, locale = "de") {
  const isNative = isNativePlattform();
  let map = undefined;

  // import map styles
  let style = (await import("./data/style.json?raw")).default;

  // replace locale in style
  const regex = /name:([a-z]{2})/gmi;
  style = JSON.parse(style.replace(regex, `name:${locale}`));

  if (isNative) {

    // import maplibregl with offline feature
    const { maplibreglOffline, addOfflinePaths } = await import("./src/index.offline");

    // init map
    map = await maplibreglOffline.OfflineMap({
      ...options,
      style: addOfflinePaths(style),
    });
  } else {
    // import maplibregl without offline feature to reduce bundle size
    const { maplibreglOnline } = await import("./src/index.online");

    // init map
    map = await new Promise((resolve, reject) => {
      const tempMap = new maplibreglOnline.Map({
        ...options,
        style: style,
      });

      tempMap.once("load", () => {
        resolve(tempMap);
      });
    });
  }

  return map;
}
