/* @jsxImportSource vue */
import "@sv/elements/portal";
import { Toast, ToastFeed } from "@sv/elements/toast";
import "./Toast.css";
import { defineCustomElement } from "vue";
import { Button } from "~/components/elements/Button";
import { Icon, type IconName } from "~/components/elements/Icon";
import { useTranslations } from "#imports";
import { MobileSafeArea } from "./MobileSafeArea";

const TOAST_TYPE = {
  default: "default",
  error: "error",
  transparent: "transparent",
};

type ToastButton = {
  label: string;
  onClick: () => void;
};

export interface ToastOptions {
  id?: string;
  message?: string;
  button?: ToastButton;
  icon?: IconName;
  time?: number;
  variant?: keyof typeof TOAST_TYPE;
}

const toasts: Map<string, Toast> = new Map();

export function toast(options: ToastOptions, toastType: typeof Toast = Toast) {
  const t = useTranslations();

  if (options.id && toasts.has(options.id)) {
    // kill existing toast with same id
    const toast = toasts.get(options.id);
    toast?.kill();
    toasts.delete(options.id);
  }
  const { time } = options;
  const toast = new toastType({ time });
  toast.className = `variant-${options.variant || TOAST_TYPE.default}`;
  toast.append(
    new ToastContent({
      message: options.message,
      button: options.button,
      icon: options.icon,
      closeButton: {
        label: t("misc.ariaLabelClose"),
        onClick: () => {
          toast.kill();
        },
      },
    }),
  );

  const feed = ToastFeed.getInstance();
  feed?.append(toast);

  if (options.id) toasts.set(options.id, toast);

  return toast;
}

export const ToastContent = defineCustomElement(
  (props: {
    message: string;
    closeButton: ToastButton;
    button?: ToastButton;
    icon?: IconName;
  }) => {
    return () => (
      <div style="pointer-events: none">
        <div class="content">
          {props.icon && <Icon class="circle-icon" name={props.icon} />}
          <div>
            <div>{props.message}</div>
            {props.button && (
              <>
                <Button
                  class="close-button"
                  onClick={props.closeButton.onClick}
                  aria-label={props.closeButton.label}
                >
                  <Icon name="close" aria-hidden="true" />
                </Button>
                <Button class="text-button" onClick={props.button.onClick}>
                  {props.button.label}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
  {
    props: ["message", "icon", "button", "closeButton"],
    styles: [
      `
      .content {
        display: flex;
      }

      .circle-icon {
        margin-top: 0.30rem;
        margin-right: 0.65rem;
        border-radius: 100%;
        width: 0.8rem;
        border: solid 1px white;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
      }

      .icon svg {
        display: block;
        font-size: 1em;
        line-height: 100%;
        stroke: currentColor;
        stroke-width: 1.5px;
        width: 0.5em;
        height: 0.5em;
      }

      button {
        all: inherit;
        cursor: pointer;
        pointer-events: auto;
      }

      button:hover {
        color: var(--color-purple-200);
      }

      .text-button {
        margin-top: 0.8rem;
        text-decoration: underline;
      }

      .close-button {
        position: absolute;
        padding: 1rem;
        right: .25rem;
        top: .25rem;
      }
      `,
    ],
  },
);

customElements.define("toast-content", ToastContent);

export function Toasts() {
  return (
    <div class="pointer-events-none fixed right-0 bottom-4 z-50 w-full lg:right-4 md:max-w-[460px]">
      <MobileSafeArea>
        <a-toast-feed class="px-4 text-base [&>*]:pointer-events-auto" />
      </MobileSafeArea>
    </div>
  );
}
