import { type LocaleKey, translation } from "@greentrails/locales";
import { useLanguage } from "#imports";

export function useTranslations() {
  const language = useLanguage();

  type i18nextOptions = { escapeValue: boolean };
  type ArgsWithKnownKey = [
    key: LocaleKey,
    args?: Record<string, string | number | i18nextOptions> & {
      interpolation?: i18nextOptions;
    },
  ];

  return (...argus: ArgsWithKnownKey) => {
    const [key, args] = argus;
    return translation(key, language.value, args);
  };
}
