import type { MetaResource } from "@greentrails/api";
import {
  useEnv,
  useHead,
  useLanguage,
  useRouter,
  useSeoMeta,
  useTranslations,
} from "#imports";

export function useMeta(meta?: {
  title?: string;
  description?: string;
  keywords?: string | null;
  image?: { url: string; alt: string };
}) {
  const env = useEnv();
  const route = useRouter();
  const lang = useLanguage();
  const t = useTranslations();

  useSeoMeta({
    title: meta?.title,
    description: meta?.description || t("meta.description"),
    ogDescription: meta?.description || t("meta.description"),
    keywords: meta?.keywords,
    ogTitle: meta?.title || t("meta.title"),
    ogUrl: `${env.SITE_URL}${route.currentRoute.value?.fullPath}`,
    ogLocale: lang.value,
    ogImage: meta?.image
      ? {
          url: meta?.image?.url,
          type: "image/png",
        }
      : `${env.SITE_URL}/share.png`,
  });
}

export function useStaticMeta() {
  const lang = useLanguage();
  const t = useTranslations();

  useHead({
    htmlAttrs: {
      lang: lang.value,
    },
    titleTemplate(str) {
      if (str) {
        return `${t("meta.title")} | ${str}`;
      }
      return t("meta.title") || "";
    },
  });

  useSeoMeta({
    ogSiteName: t("meta.title"),
    ogType: "website",
    twitterCard: "summary_large_image",
  });

  useMeta({
    description: t("meta.description"),
  });
}

export function useResourceMeta(meta?: MetaResource) {
  const env = useEnv();

  useMeta({
    title: meta?.title,
    description: meta?.description,
    // fix because api response does not have host
    image: meta?.image
      ? {
          url: `${env.PUBLIC_API_URL}${meta.image.url}`,
          alt: meta.image.alt,
        }
      : undefined,
    keywords: meta?.keywords,
  });
}
