import { log } from "package:/utils";
import { useCookie, useEnv } from "#imports";

type Flags = {
  bookmark: boolean;
  intro: boolean;
  offline: boolean;
  profile: boolean;
  events: boolean;
  news: boolean;
  report: boolean;
  api_origin: undefined | string;
};

export function useFlags() {
  const env = useEnv();

  const flags: Flags = {
    // default flag values
    bookmark: false,
    intro: true,
    offline: env.PLATFORM === "native",
    profile: false,
    events: true,
    news: true,
    report: false,
    api_origin: undefined,
  };

  const cookie = useCookie<Flags>("flags");

  try {
    if (cookie.value) {
      for (const flag in cookie.value) {
        flags[flag] = cookie.value[flag];
      }
    }
  } catch (err) {
    log.error("failed to parse flags", "err", err);
  }

  return flags;
}
