/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventResource } from '../models/EventResource';
import type { EventShortResource } from '../models/EventShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param event The event slug
   * @returns any `EventResource`
   * @throws ApiError
   */
  public eventsShow(
    event: Array<string>,
  ): CancelablePromise<{
    data: EventResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/events/{event}',
      path: {
        'event': event,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param page
   * @param perPage
   * @returns any Paginated set of `EventShortResource`
   * @throws ApiError
   */
  public eventsIndex(
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    data: Array<EventShortResource>;
    links: {
      first: string | null;
      last: string | null;
      prev: string | null;
      next: string | null;
    };
    meta: {
      current_page: number;
      from: number | null;
      last_page: number;
      /**
       * Generated paginator links.
       */
      links: Array<{
        url: string | null;
        label: string;
        active: boolean;
      }>;
      /**
       * Base path for paginator generated URLs.
       */
      path: string | null;
      /**
       * Number of items shown per page.
       */
      per_page: number;
      /**
       * Number of the last item in the slice.
       */
      to: number | null;
      /**
       * Total number of items being paginated.
       */
      total: number;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/events',
      query: {
        'page': page,
        'per_page': perPage,
      },
      errors: {
        403: `Authorization error`,
        422: `Validation error`,
      },
    });
  }
}
