import pkg from "../package.json";
import { log } from "./utils";

// Why not runtimeConfig:
// - stupid prefix NUXT_PUBLIC_
// - need to define shared vars twice
// - need to check them in a different place than defined
// - I dont like it
// - Nuxt is like this because they support webpack still,but we can just use vite api directly
// - Can call runtimeConfig only in a nuxt context

export type Env = {
  /** url pointing to api for server */
  API_URL: string;
  /** url pointing to api for client */
  PUBLIC_API_URL: string;
  /** url dynamic assets are pointing to */
  ASSETS_URL: string;
  /** public url of website */
  SITE_URL: string;
  MAPTILER_TOKEN: string;
  /** native or web */
  PLATFORM: "native" | "web";
  /** ios, android, local, stage or production */
  ENVIROMENT: "ios" | "android" | "local" | "stage" | "production";
  GENERATE: boolean;
  SENTRY_DSN: string;
  MATOMO_SITEID: string;
  MATOMO_URL: string;
  /** app version string */
  VERSION: string;
  /** app name string */
  NAME: string;
};

function isGenerate() {
  // for ssr: false
  // return Boolean(import.meta.env.PUBLIC_GENERATE || !import.meta.env.SSR);
  return Boolean(import.meta.env.PUBLIC_GENERATE);
}

export function getEnvUnchecked() {
  const source = isGenerate() ? import.meta.env : process.env;

  const env: Env = {
    MAPTILER_TOKEN: source.PUBLIC_MAPTILER_TOKEN || "",
    SITE_URL: source.PUBLIC_SITE_URL || "",
    PLATFORM: source.PUBLIC_PLATFORM || "",
    ENVIROMENT: source.PUBLIC_ENVIRONMENT || "",
    GENERATE: isGenerate(),
    API_URL: source.API_URL || "",
    PUBLIC_API_URL: source.PUBLIC_API_URL || "",
    ASSETS_URL: source.PUBLIC_ASSETS_URL || "",
    SENTRY_DSN: source.PUBLIC_SENTRY_DSN || "",
    MATOMO_SITEID: source.PUBLIC_MATOMO_SITEID || "",
    MATOMO_URL: source.PUBLIC_MATOMO_URL || "",
    VERSION: pkg.version,
    NAME: pkg.name,
  };

  return env;
}

export function getEnv() {
  const env = getEnvUnchecked();

  // Assert for missing env vars, so we know when they are missing...
  !isGenerate() && log.assert(env.API_URL, "env.API_URL needs to be defined");
  log.assert(env.PUBLIC_API_URL, "env.PUBLIC_API_URL needs to be defined");
  log.assert(env.ASSETS_URL, "env.ASSETS_URL needs to be defined");
  log.assert(env.MAPTILER_TOKEN, "env.MAPTILER_TOKEN needs to be defined");
  log.assert(env.SITE_URL, "env.SITE_URL needs to be defined");

  !env.SENTRY_DSN && log.warn("env.SENTRY_DSN is not set");
  !env.MATOMO_SITEID && log.warn("env.MATOMO_SITEID is not set");
  !env.MATOMO_URL && log.warn("env.MATOMO_URL is not set");

  return env;
}
