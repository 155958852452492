import { log } from "package:/utils";
import VueMatomo from "vue-matomo";
import { defineNuxtPlugin, onNuxtReady, useEnv, useNuxtApp } from "#imports";

declare global {
  interface Window {
    _paq: any[];
  }
}

export default defineNuxtPlugin(() => {
  const env = useEnv();

  onNuxtReady(async () => {
    const app = useNuxtApp();
    // disable tracking in dev mode and on the server
    if (!import.meta.env.DEV && env.MATOMO_URL) {
      app.vueApp.use(VueMatomo, {
        host: env.MATOMO_URL,
        siteId: env.MATOMO_SITEID,
        disableCookies: true,
        enableLinkTracking: true,
        requireCookieConsent: false,
        trackInitialView: true,
        router: app.$router,
      });
      // respect do not tracking setting (e.g. firefox)
      window._paq?.push(["setDoNotTrack", true]);
      log.info("Matomo tracking initialized");
    } else {
      log.info("Matomo tracking disabled in dev mode");
    }
  });
});
