/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventShortResource } from '../models/EventShortResource';
import type { OpeningTimesResource } from '../models/OpeningTimesResource';
import type { PoiCategoryResource } from '../models/PoiCategoryResource';
import type { PoiShortResource } from '../models/PoiShortResource';
import type { RoundGeoResource } from '../models/RoundGeoResource';
import type { RoundResource } from '../models/RoundResource';
import type { RoundShortResource } from '../models/RoundShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RoundService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param round The round slug
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiCategoryResource`
   * @throws ApiError
   */
  public roundPoiCategories(
    round: Array<string>,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiCategoryResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}/pois/categories',
      path: {
        'round': round,
      },
      query: {
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param round The round slug
   * @param hasCategory
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiShortResource`
   * @throws ApiError
   */
  public roundsPois(
    round: Array<string>,
    hasCategory?: string | null,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}/pois',
      path: {
        'round': round,
      },
      query: {
        'has_category': hasCategory,
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param round The round slug
   * @returns any `OpeningTimesResource`
   * @throws ApiError
   */
  public roundsState(
    round: Array<string>,
  ): CancelablePromise<{
    data: OpeningTimesResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}/state',
      path: {
        'round': round,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param round The round slug
   * @returns any `RoundGeoResource`
   * @throws ApiError
   */
  public roundsGeoData(
    round: Array<string>,
  ): CancelablePromise<{
    data: RoundGeoResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}/geo-data',
      path: {
        'round': round,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param round The round slug
   * @returns any Array of `EventShortResource`
   * @throws ApiError
   */
  public roundsEvents(
    round: Array<string>,
  ): CancelablePromise<{
    data: Array<EventShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}/events',
      path: {
        'round': round,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param round The round slug
   * @returns any `RoundResource`
   * @throws ApiError
   */
  public roundsShow(
    round: Array<string>,
  ): CancelablePromise<{
    data: RoundResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds/{round}',
      path: {
        'round': round,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @returns any Array of `RoundShortResource`
   * @throws ApiError
   */
  public roundsIndex(): CancelablePromise<{
    data: Array<RoundShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/rounds',
    });
  }
}
