import logger from "@luckydye/log";
import i18next from "i18next";

import de from "./languages/de.json";
import en from "./languages/en.json";

const log = logger().time(false).prefix("@greentrails/locales").trace();

// https://www.i18next.com/overview/api

i18next.init(
  {
    // partialBundledLanguages: true, /* partialy from backend */
    fallbackLng: "de",
    defaultNS: "global",
    resources: {},
  },
  (err) => {
    if (err) return log.error("something went wrong loading", err);
    log.info("i18next loaded");
  },
);

i18next.addResourceBundle("de", "global", de);
i18next.addResourceBundle("en", "global", en);

type LocaleDefinitions = typeof de;
export type LocaleKey = keyof LocaleDefinitions;

export function translation(
  id: string,
  lang: string,
  args: Record<string, number | string> | undefined = {},
): string | undefined {
  return i18next.t(id, { lng: lang, ...args });
}
