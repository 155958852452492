import { defineComponent, ref } from "vue";
import "@sv/elements/blur";

export const Dialog = defineComponent(
  (props: { open?: boolean }, { slots }) => {
    const open = ref(props.open);

    return () => (
      <a-blur
        enabled={open.value || undefined}
        class={[
          "group/dialog fixed top-0 left-0 z-50 block h-full w-full transition-all",
          "[&[enabled]]:bg-[#00000066]",
        ]}
        onBlur={(e) => {
          e.preventDefault();
        }}
      >
        <div
          class={[
            "rounded-lg bg-green-800 px-8 py-8 opacity-0 transition-all",
            "-translate-x-1/2 -translate-y-1/2 fixed top-1/2 left-1/2 w-[400px] max-w-[95vw]",
            "scale-95 group-[&[enabled]]/dialog:block group-[&[enabled]]/dialog:scale-100 group-[&[enabled]]/dialog:opacity-100",
          ]}
        >
          {slots.default?.()}
        </div>
      </a-blur>
    );
  },
  {
    props: ["open"],
  },
);
