/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventShortResource } from '../models/EventShortResource';
import type { OpeningTimesResource } from '../models/OpeningTimesResource';
import type { PoiCategoryResource } from '../models/PoiCategoryResource';
import type { PoiGeoResource } from '../models/PoiGeoResource';
import type { PoiResource } from '../models/PoiResource';
import type { PoiShortResource } from '../models/PoiShortResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PoiService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiCategoryResource`
   * @throws ApiError
   */
  public poisCategories(
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiCategoryResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/categories',
      query: {
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param poi The poi slug
   * @returns any `OpeningTimesResource`
   * @throws ApiError
   */
  public poisState(
    poi: Array<string>,
  ): CancelablePromise<{
    data: OpeningTimesResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/{poi}/state',
      path: {
        'poi': poi,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param poi The poi slug
   * @param hasCategory
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiShortResource`
   * @throws ApiError
   */
  public poisPois(
    poi: Array<string>,
    hasCategory?: string | null,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/{poi}/pois',
      path: {
        'poi': poi,
      },
      query: {
        'has_category': hasCategory,
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        404: `Not found`,
        422: `Validation error`,
      },
    });
  }
  /**
   * @param poi The poi slug
   * @returns any `PoiGeoResource`
   * @throws ApiError
   */
  public poisGeoData(
    poi: Array<string>,
  ): CancelablePromise<{
    data: PoiGeoResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/{poi}/geo-data',
      path: {
        'poi': poi,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param poi The poi slug
   * @returns any Array of `EventShortResource`
   * @throws ApiError
   */
  public poisEvents(
    poi: Array<string>,
  ): CancelablePromise<{
    data: Array<EventShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/{poi}/events',
      path: {
        'poi': poi,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param poi The poi slug
   * @returns any `PoiResource`
   * @throws ApiError
   */
  public poisShow(
    poi: Array<string>,
  ): CancelablePromise<{
    data: PoiResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois/{poi}',
      path: {
        'poi': poi,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * @param hasCategory
   * @param hasArea
   * @param highlight
   * @returns any Array of `PoiShortResource`
   * @throws ApiError
   */
  public poisIndex(
    hasCategory?: string | null,
    hasArea?: string | null,
    highlight?: boolean | null,
  ): CancelablePromise<{
    data: Array<PoiShortResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pois',
      query: {
        'has_category': hasCategory,
        'has_area': hasArea,
        'highlight': highlight,
      },
      errors: {
        403: `Authorization error`,
        422: `Validation error`,
      },
    });
  }
}
