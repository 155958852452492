//
export * from "./gen/index.js";

import type { ApiRequestOptions } from "./gen/core/ApiRequestOptions.js";
import { request as __request } from "./gen/core/request";
import {
  ApiError,
  BaseHttpRequest,
  CancelablePromise,
  type OpenAPIConfig,
} from "./gen/index.js";
import { AreaProgressService } from "./gen/services/AreaProgressService";
import { AreaService } from "./gen/services/AreaService";
import { PageService } from "./gen/services/PageService";
import { PoiService } from "./gen/services/PoiService";
import { RoundService } from "./gen/services/RoundService";
import { TrailService } from "./gen/services/TrailService";
import { NewsService } from "./gen/services/NewsService";
import { EventService } from "./gen/services/EventService";
import { IndexService } from "./gen/services/IndexService";
import { debug, error } from "./util";

const API_VERSION = "0.0.1";

// https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#does_browser-stored_data_persist

export class FetchError extends Error {
  public readonly url: string;

  constructor(request: ApiRequestOptions, message: string) {
    super(message);

    this.name = "FetchError";
    this.url = request.url;
  }
}

type FetchMiddleware<T> = (req: ApiRequestOptions) => Promise<T | void> | void;

class StoredFetchHttpRequest extends BaseHttpRequest {
  constructor(public config: OpenAPIConfig) {
    super(config);
  }

  on(callback: FetchMiddleware<any>) {
    this.middlewares.add(callback);

    return () => {
      this.middlewares.delete(callback);
    };
  }

  middlewares = new Set<FetchMiddleware<any>>();

  middleware(req: ApiRequestOptions) {
    for (const middleware of this.middlewares) {
      const insert = middleware(req);
      if (insert) return insert;
    }
    return;
  }

  public request<T>(req: ApiRequestOptions): CancelablePromise<T> {
    debug("request", "req", req);

    return new CancelablePromise(async (ok, err, onCancel) => {
      try {
        // TODO: implement onCancel

        const insert = await this.middleware(req)?.catch((err) => {
          error("middleware", "err", err);
        });

        if (insert) {
          debug("middleware", "options", req, "insert", insert);
          return ok(insert);
        }

        const res = await __request<T & { __path: string }>(this.config, req);
        res.__path = `${req.url}${req.query ? `?${JSON.stringify(req.query)}` : ""}`;

        ok(res);
      } catch (error: unknown) {
        if (error instanceof ApiError) {
          err(error);
        } else {
          err(new FetchError(req, (error as Error).message));
        }
      }
    });
  }
}

export class GreenTrailsApiClient {
  public readonly area: AreaService;
  public readonly areaProgress: AreaProgressService;
  public readonly page: PageService;
  public readonly poi: PoiService;
  public readonly round: RoundService;
  public readonly trail: TrailService;
  public readonly news: NewsService;
  public readonly event: EventService;
  public readonly index: IndexService;
  public readonly request: StoredFetchHttpRequest;

  constructor(
    public options: {
      baseUrl: string;
      assetsUrl?: string;
      lang?: string;
    },
  ) {
    this.request = new StoredFetchHttpRequest({
      BASE: options.baseUrl,
      VERSION: API_VERSION,
      WITH_CREDENTIALS: false,
      CREDENTIALS: "include",
      HEADERS: options.lang
        ? {
            "Accept-Language": options.lang,
          }
        : {},
    });

    this.area = new AreaService(this.request);
    this.areaProgress = new AreaProgressService(this.request);
    this.page = new PageService(this.request);
    this.poi = new PoiService(this.request);
    this.round = new RoundService(this.request);
    this.trail = new TrailService(this.request);
    this.news = new NewsService(this.request);
    this.event = new EventService(this.request);
    this.index = new IndexService(this.request);
  }
}
