import { defineNuxtPlugin } from "#imports";

import * as map from "@greentrails/map";

// biome-ignore lint/style/noDefaultExport: nuxt style
export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      map: map,
    },
  };
});
